import loadable from '@loadable/component'
import cn from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useContext, useEffect } from 'react'

import { Col, Container, Row } from '@kogk/common'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { Link } from '@kogk/gatsby-theme-base'

import { Card, MainLayout } from '@cmp/site/'
import { FooterVisibilityContext } from '@root/src/context/FooterVisibilityContext'
import { MenuTypeContext } from '@root/src/context/MenuTypeContext'
import { pluralize } from '@root/src/utils/index'
import { Pagination } from '@src/components/site'
import { GlobalDataProvider } from '@src/data/global-data'
import useIsMobile from '@root/src/hooks/useIsMobile'
import Filters from '../../sections/Filters'

const MobileFilters = loadable(() => import('../../sections/MobileFilters'))
const FeaturedBrands = loadable(() => import('../../sections/FeaturedBrands'))

import styles from './OverviewTemplate.module.scss'

const popularProductQuery = graphql`
  query {
    allPrismicPopularBrands {
      nodes {
        ...fragmentPrismicPopularBrands
      }
    }
  }
`

export default ({
  path = '/vorur',
  query,
  category,
  currentPage,
  totalPages,
  products,
  totalProducts,
  subcategories,
  selectedSubcategories,
  brands,
  selectedBrands,
  onFilterChange,
  minPriceAvailable,
  maxPriceAvailable,
  minPriceSelected,
  maxPriceSelected,
  order,
  makeHref
}) => {
  const isMobile = useIsMobile(767)
  const { language } = useLanguage()
  const { modifyVisibility } = useContext(FooterVisibilityContext)
  const { modify } = useContext(MenuTypeContext)

  const popularBrandsSectionData = useStaticQuery(popularProductQuery)
  const currentPageCategoryId = category?.categoryId
  const isSnyrtivara = currentPageCategoryId === 'SNYRTIVARA'

  const currPagePopularBrandsSectionData = popularBrandsSectionData.allPrismicPopularBrands?.nodes.filter(
    item => item.data.category_id.text === currentPageCategoryId
  )
  const currPagePopularBrandsSectionDataObject = currPagePopularBrandsSectionData
    ? currPagePopularBrandsSectionData.pop()?.data
    : {}

  useEffect(() => {
    modify('primary')
    modifyVisibility(true)
  }, [])

  const title = query ? `„${query}“` : category && category.title

  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <MainLayout isSnyrtivara={isSnyrtivara}>
        <div className={styles.filterContainer}>
          {isMobile === true && (
            <MobileFilters
              totalProducts={totalProducts}
              isSnyrtivara={isSnyrtivara}
              searchFieldProps={{
                placeholder: `Vöruleit ${category?.title.toLowerCase()}`,
                onChange: e => onFilterChange('q', e.target.value)
              }}
              priceFieldProps={{
                minPrice: minPriceAvailable,
                maxPrice: maxPriceAvailable,
                currentMin: minPriceSelected,
                currentMax: maxPriceSelected,
                onChangeMin: v => onFilterChange('min', v),
                onChangeMax: v => onFilterChange('max', v)
              }}
              sortFieldProps={{
                selected: order,
                onChange: v => onFilterChange('order', v)
              }}
              subcategoryFieldProps={{
                allItems: subcategories,
                selected: selectedSubcategories,
                onChange: selected => onFilterChange('s', selected)
              }}
              brandFieldProps={{
                allItems: brands,
                selected: selectedBrands,
                onChange: selected => onFilterChange('b', selected)
              }}
            />
          )}
          <Filters
            isSnyrtivara={isSnyrtivara}
            searchFieldProps={{
              placeholder: `Vöruleit ${category?.title.toLowerCase()}`,
              onChange: e => onFilterChange('q', e.target.value)
            }}
            priceFieldProps={{
              minPrice: minPriceAvailable,
              maxPrice: maxPriceAvailable,
              currentMin: minPriceSelected,
              currentMax: maxPriceSelected,
              onChangeMin: v => onFilterChange('min', v),
              onChangeMax: v => onFilterChange('max', v)
            }}
            sortFieldProps={{
              selected: order,
              onChange: v => onFilterChange('order', v)
            }}
            subcategoryFieldProps={{
              allItems: subcategories,
              selected: selectedSubcategories,
              onChange: selected => onFilterChange('s', selected)
            }}
            brandFieldProps={{
              allItems: brands,
              selected: selectedBrands,
              onChange: selected => onFilterChange('b', selected)
            }}
          />
        </div>
        <Container className={cn({ [styles.blackTheme]: isSnyrtivara })}>
          <Row>
            <Col col={12} className='pb-md-1 pt-3 pt-md-4'>
              {title && (
                <h1 className={cn('blue mb-2 pb-1', styles.categoryHeading)}>
                  {title}
                </h1>
              )}
              {currPagePopularBrandsSectionDataObject && (
                <FeaturedBrands {...currPagePopularBrandsSectionDataObject} />
              )}
              {totalProducts !== undefined && (
                <p
                  className={cn(
                    styles.subHeading,
                    'parag parag--x-small opacity--05'
                  )}
                >
                  {totalProducts} {pluralize(totalProducts, 'vara', 'vörur')}
                </p>
              )}
            </Col>
          </Row>

          <Row className='flex-wrap px-2 px-md-0'>
            {products.map(item => {
              return (
                <Card
                  key={item.id}
                  url={`/${item.category.slug}/${item.id}`}
                  prodDetail={item.title}
                  price={item.fullPrice}
                  discount={item.discountPercent}
                  discountPrice={item.discountPrice}
                  image={
                    item.images[0] &&
                    (item.images[0].largeUrl ?? item.images[0].thumbnailUrl)
                  }
                  prodId={item.id}
                  className='px-1 mb-3 mb-lg-4 col-6 col-sm-4 col-md-3 col-lg-2'
                />
              )
            })}
          </Row>

          {totalPages > 0 && (
            <Pagination
              isSnyrtivara={isSnyrtivara}
              currentPage={currentPage}
              totalPages={totalPages}
              renderLink={({ page, ...props }) => (
                <Link
                  to={path + makeHref({ page: page === 1 ? undefined : page })}
                  {...props}
                />
              )}
            />
          )}
        </Container>
      </MainLayout>
    </GlobalDataProvider>
  )
}
